import { defineMessages } from 'react-intl'

export default defineMessages({
    acceptButton: {
        defaultMessage: 'Continuar',
    },
    acceptButtonLoading: {
        defaultMessage: 'Aceptando condiciones...',
    },
    otherOptionsButton: {
        defaultMessage: 'Seleccionar Condiciones',
    },
    acceptConditions: {
        defaultMessage: 'Soy mayor de edad y acepto {policy}, y {terms}',
    },
})
