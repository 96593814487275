import getTheme from 'commons/js/theme/getTheme'

const extendedTheme = {
    ...getTheme(),
    // NOTE: We need to override this as ParkingSearch component uses grid-styled
    // for layout, instead of custom Grid used in merchants
    breakpoints: [32, 48, 64], // in ems
}

export default extendedTheme
