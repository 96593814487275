// Needed to support async await
import 'regenerator-runtime/runtime'
import App from './App'
import render from 'commons/js/util/render'
import { init } from 'commons/js/util/reporter'

init()

const modalElement = document.querySelectorAll('.js-tos-modal')[0]

render({ App: App, element: modalElement })
