/* eslint-disable xss/no-mixed-html */
import React, { useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import messages from './messages'
import ErrorText from 'tos/js/ui/ErrorText'
import { Image, Button, Box, Copy, Checkbox, CheckButton } from '@elparking/components'
import Routes from 'commons/js/routes/routes'

const Terms = ({ intl, error, onSubmit, isSubmitting }) => {
    const [accept, setAccept] = useState(false)
    return <Box w='100%' padding={['0 20px 20px 20px', '0 2em 2em 2em']}>
        <Box w='100%' textAlign='center' mb='1em'>
            <Image src='/images/tos.svg' alt='TOS' />
        </Box>
        <Box w='100%' textAlign='center'>
            <Copy padding='0' fontSize='xxLarge' textAlign='center' fontWeight='book' fontColor='black'>
                <FormattedMessage defaultMessage='Hemos actualizado nuestros términos y condiciones' />
            </Copy>
            <Copy padding='1em 0' fontSize='medium' fontWeight='light' fontColor='gray80' textAlign='center'>
                <FormattedMessage defaultMessage='Para poder acceder a nuestros servicios, es necesario que aceptes los términos y condiciones de uso.' />
            </Copy>
            <Box w='100%'>
                <Box as='span' textAlign='left'>
                    <Checkbox
                      checkButtonElement={CheckButton.squared}
                      id='acceptTermsCheck'
                      name='acceptTermsCheck'
                      justifyContent='start'
                      label={<Copy
                        key='acceptTermsCheckText'
                        fontSize='xSmall'
                        fontColor='gray80'
                        fontWeight='regular'
                        lineHeight='small'
                        padding='0'
                            >{intl.formatMessage(messages.acceptConditions, {
                            policy: <Copy
                              as='a'
                              key='acceptTermsCheckCookieText'
                              padding='inherit'
                              fontColor='gray'
                              fontSize='inherit'
                              rel='noopener noreferrer nofollow'
                              href={Routes.cookiesPath()}
                              target='_blank'>
                                <FormattedMessage
                                  defaultMessage='la política de privacidad'
                                />
                            </Copy>,
                            terms: <Copy
                              as='a'
                              key='acceptTermsCheckText'
                              padding='inherit'
                              fontColor='gray'
                              fontSize='inherit'
                              rel='noopener noreferrer nofollow'
                              href={Routes.termsAndConditionsPath()}
                              target='_blank'>
                                <FormattedMessage
                                  defaultMessage='los términos y condiciones'
                                />
                            </Copy>,
                            })}</Copy>
                        }
                      checked={accept}
                      onChange={() => setAccept(!accept)} />
                </Box>
            </Box>
        </Box>
        <Box mt='1.5em' error={error} textAlign='center'>
            <Button dataTest='accept-gdpr' borderRadius='20px' height='45px' width={['100%', '300px']} onClick={() => onSubmit()} disabled={isSubmitting || !accept}>
                {isSubmitting
                    ? intl.formatMessage(messages.acceptButtonLoading)
                    : intl.formatMessage(messages.acceptButton)}
            </Button>
        </Box>
        { error && !isSubmitting && <Box mt='1.5em' error={error} textAlign='center' background='error10' p='1em'>
            <ErrorText>{error}</ErrorText>
        </Box>}
    </Box>
}

Terms.propTypes = {
    intl: PropTypes.object,
    isSubmitting: PropTypes.bool,
    error: PropTypes.any,
    onSubmit: PropTypes.func,
}

export default injectIntl(Terms)
