/* istanbul ignore file */
import { localStorage } from '@elparking/utils'
import { TRADENAME } from 'commons/js/constants'
const PRIVACY_AVOIDED = TRADENAME + ':isPrivacyAvoided'

export function getCookiePrivacyAvoided () {
  return !!localStorage.get(PRIVACY_AVOIDED)
}

export function setCookiePrivacyAvoided () {
  localStorage.save(PRIVACY_AVOIDED, 'true')
}
