import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import messages from './messages'
import Terms from './Terms'
import theme from '../../theme'
import { EPProvider, Modal } from '@elparking/components'
import convertConstantToEPProviderFormat from 'commons/js/util/coverterGlobalConfig'

const TOSModal = ({
    modalComponent: ModalComponent,
    tosUrl,
    intl,
    acceptTOS,
}) => {
    const [ isOpen, setIsOpen ] = useState(true)
    const [ isSubmitting, setIsSubmitting ] = useState(false)
    const [ error, setError ] = useState(null)

    const onSubmit = (privacyOptions) => {
        setIsSubmitting(true)

        acceptTOS(privacyOptions).then((response) => {
            if (response.error) {
                setError(intl.formatMessage(messages.modalTOSError))
                setIsSubmitting(false)
                return
            }

            setIsOpen(false)
            setIsSubmitting(true)
        }).catch(() => {
            setError(intl.formatMessage(messages.modalTOSError))
            setIsSubmitting(false)
        })
    }

    return (
        <EPProvider theme={theme} {...convertConstantToEPProviderFormat()}>
            <ModalComponent
              isShown={isOpen}
              showCloseButton={false}
              width='700px'
              padding='0'
              zIndex={9999}
            >
                <Terms
                  isSubmitting={isSubmitting}
                  tosUrl={tosUrl}
                  onSubmit={onSubmit}
                  error={error} />
            </ModalComponent>
        </EPProvider>
    )
}

TOSModal.propTypes = {
    intl: PropTypes.object,
    modalTitle: PropTypes.string,
    modalDescription: PropTypes.string,
    tosUrl: PropTypes.string,
    modalComponent: PropTypes.any,
    acceptTOS: PropTypes.func,
    version: PropTypes.string,
}

TOSModal.defaultProps = {
    modalComponent: Modal,
    acceptTOS: () => {},
}

export default injectIntl(TOSModal)
