import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { areTOSAccepted, acceptTOS } from 'tos/js/services/tosChecker'
import TOSAdvancedProfile from '../TOSAdvancedProfile'
import { BrowserWrapper } from '@elparking/components'
import { localStorage } from '@elparking/utils'

function getAuthToken (defaultToken) {
  try {
    return localStorage.get('AUTH_TOKEN') || defaultToken
  } catch (e) {
    return defaultToken
  }
}

const TOSFetcher = ({
    areTOSAccepted,
    acceptTOS,
    renderModal,
    show: initialShow,
    showPrivacy: initialShowPrivacy,
    defaultToken,
  }) => {
    const [show, setShow] = useState(initialShow)
    const [showPrivacy, setShowPrivacy] = useState(initialShowPrivacy)
    const [tosData, setTOSData] = useState({})
    const [localStorageValue, setLocalStorageValue] = useState(getAuthToken(defaultToken))

    useEffect(() => {
      const handleStorageChange = (event) => {
          event.data.eventId === 'check_tos' && setLocalStorageValue(localStorage.get('AUTH_TOKEN') || '')
      }
      window.addEventListener('message', handleStorageChange)
      return () => {
        window.removeEventListener('message', handleStorageChange)
      }
    }, [])
    useEffect(() => {
      localStorageValue && areTOSAccepted().then((response) => {
        if (response.error) {
          return
        }
        if (!response.version) {
          setShowPrivacy(true)
          setShow(false)
          return
        }
        setShow(true)
        setTOSData(response)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorageValue])

    const mapModalProps = () => {
      const { title, description, url, version } = tosData
      return {
        modalTitle: title,
        modalDescription: description,
        tosUrl: url,
        version: version,
        acceptTOS: () => acceptTOS(version),
      }
    }

    if (showPrivacy) {
      return <BrowserWrapper><TOSAdvancedProfile /></BrowserWrapper>
    }

    return show && renderModal(mapModalProps())
  }

  TOSFetcher.propTypes = {
    areTOSAccepted: PropTypes.func,
    acceptTOS: PropTypes.func,
    renderModal: PropTypes.func.isRequired,
    show: PropTypes.bool,
    showPrivacy: PropTypes.bool,
    defaultToken: PropTypes.string,
  }

  TOSFetcher.defaultProps = {
    areTOSAccepted,
    acceptTOS,
    show: null,
    showPrivacy: null,
    defaultToken: '',
  }

export default TOSFetcher
