import { hot } from 'react-hot-loader/root'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { defaultLocale, currentLocale } from 'commons/js/locale'
import { messages } from 'commons/js/messages'
import TOSFetcher from './components/TOSFetcher'
import TOSModal from './components/TOSModal'

function renderModal (props) {
    return (<TOSModal {...props} />)
}

const App = () => (
    <IntlProvider defaultLocale={defaultLocale} locale={currentLocale} messages={messages[currentLocale]}>
        <TOSFetcher renderModal={renderModal} />
    </IntlProvider>
)

export default hot(App)
