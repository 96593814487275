import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Flex, Copy, Button, Box } from '@elparking/components'
import ErrorText from 'tos/js/ui/ErrorText'
import Routes from 'commons/js/routes/routes'
const QuestionContent = ({
    error,
    loading,
    onCancel,
    onAccept,
    title,
    description,
    image,
}) => {
    return (
        <Flex dataTest='tos-privacy-modal' mt={['0px', '2em']} flexDirection='column' textAlign='center'>
            {image}
            <Copy size={['large', 'xxLarge']} fontWeight='medium' color='gray' padding='1em 0em'>
                {title}
            </Copy>
            {description}
            <Copy size='small' fontWeight='regular'>
                <FormattedMessage defaultMessage='Si quieres saber más accede a {gdpr}.' values={{
                    gdpr: <Copy as='a' size='small' fontWeight='bold' href={Routes.termsAndConditionsPath()} rel='noopener noreferrer' target='_blank'><FormattedMessage defaultMessage='nuestra política de protección de datos' /></Copy>,
                }} />
            </Copy>
            {error && !loading &&
                <Box mt='1em'>
                    <ErrorText>{error}</ErrorText>
                </Box>
            }
            <Flex width={'100%'} alignContent='center' mt='1em' mb={['0em', '1em']} flexDirection={['column-reverse', 'row']}>
                <Button.bordered dataTest='tos-privacy-modal-ask-again-button' disabled={loading} width='100%' onClick={() => onCancel(true)} fontWeight='bold' mr={['0em', '1em']}> <FormattedMessage defaultMessage='No permitir' /></Button.bordered>
                <Button dataTest='tos-privacy-modal-submit' disabled={loading} width='100%' onClick={() => onAccept()} fontWeight='bold' mb={['1em', '0em']}> <FormattedMessage defaultMessage='Permitir' /></Button>
            </Flex>
        </Flex>
    )
}

QuestionContent.propTypes = {
    error: PropTypes.any,
    loading: PropTypes.bool,
    onCancel: PropTypes.func,
    onAccept: PropTypes.func,
    title: PropTypes.any,
    description: PropTypes.any,
    image: PropTypes.node,
}

QuestionContent.defaultProps = {
    image: null,
}

export default injectIntl(QuestionContent)
