import * as api from 'commons/js/api'

const defaultPrivacyOptions = {
    privacyOptionOne: true,
    privacyOptionThree: true,
}

function mapPrivacyOptions (privacyOptions) {
    const { privacyOptionOne, privacyOptionTwo, privacyOptionThree } = privacyOptions
    if (privacyOptionTwo === undefined) {
        return {
            privacy: {
                sendAdvertisingNotifications: privacyOptionOne,
                shareAdvertisingDataWithThirdParties: privacyOptionThree,
            },
        }
    }
    return {
        privacy: {
            sendAdvertisingNotifications: privacyOptionOne,
            profileForAdvertising: privacyOptionTwo,
            shareAdvertisingDataWithThirdParties: privacyOptionThree,
        },
    }
}

export function areTOSAccepted () {
    return api.getTOS()
}

export function getPrivacy () {
    return api.getPrivacy()
}

export function setPrivacy (privacy) {
    return api.setPrivacy({privacy})
}

export function acceptTOS (version, privacyOptions = defaultPrivacyOptions) {
    return api.acceptTOS(version).then((response) => {
        if (response.error) {
            throw new Error(response.error)
        }
        return api.setPrivacy(mapPrivacyOptions(privacyOptions))
    })
}
