import { defineMessages } from 'react-intl'

export default defineMessages({
    modalTOSNotice: {
        defaultMessage: 'Estamos actualizando nuestra política de privacidad porque para nosotros es muy importante proteger tus datos y aumentar la transparencia. Por este motivo queremos que te resulte fácil personalizar tu experiencia.',
    },
    modalTOSError: {
        defaultMessage: 'Ha habido algún problema aceptando las condiciones.',
    },
    modalTOSAcceptButton: {
        defaultMessage: 'Aceptar todas las condiciones',
    },
    modalTOSAcceptButtonLoading: {
        defaultMessage: 'Aceptando condiciones...',
    },
    modalTOSOtherOptionsButton: {
        defaultMessage: 'Seleccionar Condiciones',
    },
    modalTOSTextLink: {
        defaultMessage: 'Consulta las condiciones de privacidad {link}.',
    },
    modalTOSLink: {
        defaultMessage: 'aquí',
    },
    privacyOptionOneTitle: {
        defaultMessage: 'Mejora la información y comunicaciones que recibes',
    },
    privacyOptionOneDescription: {
        defaultMessage: 'Ahora puedes mejorar la información y comunicaciones comerciales sobre posibles ventajas, promociones y ofertas que recibes, ya sean nuestras o de nuestros colaboradores, que pudieran ser de tu interés. Puedes cambiarlo cuándo quieras en el apartado de configuración de privacidad.',
    },
    privacyOptionTwoTitle: {
        defaultMessage: 'Personaliza tu experiencia con nosotros',
    },
    privacyOptionTwoDescription: {
        defaultMessage: 'Para mejorar los servicios, ofertas, ventajas y promociones que puedes recibir, y así poder personalizar mejor las mismas, evitando que recibas cualquier tipo de comunicación que no te interese, podemos hacer perfiles de usuarios con pleno respeto a tus derechos. Puedes cambiarlo cuando quieras en el apartado de configuración de privacidad.',
    },
    privacyOptionThreeTitle: {
        defaultMessage: 'Comparte tus datos con nuestros colaboradores',
    },
    privacyOptionThreeDescription: {
        defaultMessage: 'A fin de mejorar la información y comunicaciones que recibes, según lo descrito con anterioridad, así como para personalizar las mismas a efectos promocionales, nos autorizas a compartir tus datos personales mínimos, incluyendo los de localización e ID de Publicidad, cuando no se hubieran bloqueado previamente por el usuario, con nuestros colaboradores a estos fines.',
    },
})
